.system-settings {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
  
    height: 100%; /* Ensure it takes the full height of the sidebar */
}

.system-settings h3 {
    margin-bottom: 20px;
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
}

/* Add padding and background to distinguish sections */
.settings-section {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.settings-section h4 {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: #007bff;
}

.settings-section select,
.settings-section input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.settings-section label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

/* Styling for wavelength group */
.wavelength-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.wavelength-group input {
    width: 48%;
}

button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.field-group {
    margin-bottom: 10px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.button-group button {
    margin-right: 10px; /* Adjust the spacing between buttons */
}

.button-group button:last-child {
    margin-right: 0; /* Ensure the last button doesn't have extra space after it */
}

