/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Top Ribbon styles */
.top-ribbon {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  width: 100%; /* Ensures the ribbon extends horizontally */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Keeps the ribbon on top of other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.top-ribbon ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.top-ribbon li {
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}

.top-ribbon .dropdown {
  display: none;
  position: absolute;
  background-color: #007bff;
  list-style-type: none;
  padding: 5px 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.top-ribbon li:hover .dropdown {
  display: block;
}

.top-ribbon .dropdown li {
  padding: 5px 15px;
  white-space: nowrap;
}

/* Layout */
.layout {
  display: flex;
  padding-top: 50px; /* Space for the fixed top ribbon */
}

/* Sidebar */
.sidebar {
  width: 20%; /* Adjusted to leave room for Lens Data Editor */
  background-color: #e9ecef;
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Ensures the sidebar scrolls vertically */
  height: calc(100vh - 50px); /* Full height minus the height of the top ribbon */
  position: fixed; /* Fixed sidebar */
  top: 50px; /* Aligns with the top ribbon */
  left: 0;
  bottom: 0;
  z-index: 999; /* Ensure it stays on top of content */
}

/* Ensure smooth scrolling for better UX */
html {
  scroll-behavior: smooth;
}

/* Container that holds the main content */
.main-content {
  flex-grow: 1;
  margin-left: 22%; /* Leaves space for the sidebar */
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* Top Ribbon styles */
.top-ribbon {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  width: 100%; /* Ensures the ribbon extends horizontally */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Keeps the ribbon on top of other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.surface-table {
  flex-grow: 1;
  background-color: #f4f4f4;
  padding: 20px;
  margin-bottom: 20px;
}

.layout-pane {
  flex-grow: 1;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Plot card styles */
.plot-card {
  margin-bottom: 20px;
}

.plot-card h4 {
  margin-bottom: 10px;
}

.plot-card img {
  max-width: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Button styles */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
}

.update-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .layout {
      flex-direction: column;
  }

  .sidebar {
      width: 100%;
      height: auto;
      box-shadow: none;
  }

  .main-content {
      margin-left: 0;
      padding: 10px;
  }
}
